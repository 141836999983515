import { render, staticRenderFns } from "./SearchTypeWidgetDisplay.vue?vue&type=template&id=5138816f"
import script from "./SearchTypeWidgetDisplay.vue?vue&type=script&lang=js"
export * from "./SearchTypeWidgetDisplay.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports