var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{attrs:{"id":"dashboard-analytics"}},[_c('div',{staticClass:"vx-row match-height"},[_vm._l((_vm.widgetList),function(widget,index){return _c('div',{key:index,staticClass:"vx-col w-full mb-base",class:widget.cssGridSettings},[_c(widget.componentName,_vm._b({tag:"component",on:{"deleteWidget":function($event){return _vm.handleRemoveWidget(widget.instanceId)}}},'component',{ 
                dashboardId: _vm.dashboardId,
                chartData: widget.chartData,
                chartOptions: widget.chartOptions,
                isReadonly: _vm.isDashboardReadonly,
                isCompanyDashboard: _vm.isCompanyDashboard,
                applicationId: _vm.applicationId,
                widgetData: {
                  templateId: widget.templateId,
                  instanceId: widget.instanceId,
                  name: widget.name,
                  title: widget.title}
              },false))],1)}),(!_vm.isCompanyDashboard && _vm.isDashboardDefault && _vm.appStatsUrl)?_c('div',{staticClass:"vx-col w-full mb-base"},[_c('clicky-analytics-display',{attrs:{"applicationId":_vm.applicationId}})],1):_vm._e()],2)]),_c('vs-prompt',{attrs:{"title":"Remove Widget","accept-text":"Remove","active":_vm.showRemoveWidgetPopup},on:{"cancel":_vm.handleCancelDeleteWidget,"close":_vm.handleCancelDeleteWidget,"accept":_vm.deleteWidget,"update:active":function($event){_vm.showRemoveWidgetPopup=$event}}},[_c('div',{staticClass:"vx-row mt-2 mb-2"},[_c('div',{staticClass:"vx-col w-full"},[_c('div',{},[_vm._v("Are you sure you want to remove the widget '"+_vm._s(_vm.currentWidgetForDeletion && _vm.currentWidgetForDeletion.title)+"'?")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }