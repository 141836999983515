<template>
  <vs-popup 
    title="Add Widget" 
    :active.sync="show"
    :button-close-hidden='true'
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="vx-row mt-2 mb-4">
      <div class="vx-col w-full">
        <label class="text-sm">Select Widget:</label>
        <v-select v-model="selectedWidget" :options="widgetOptions" />
      </div>
    </div>
    <div class="ml-auto">
      <vs-button @click="addWidget"  class="float-right ml-2" color="primary" type="filled">Add</vs-button>      
      <vs-button @click="closeAddWidgetDialog" class="float-right" color="danger" type="border">Cancel</vs-button>      
    </div>
  </vs-popup>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect
  },
  props: {
    dashboardId: { type: String, required: true },
    showPopup: { type: Boolean, required: true }
  },
  data () {
    return {
      show: false,
      selectedWidget: null
    }
  },
  watch:  {
    showPopup (newVal, oldVal) {
      this.show = newVal
    }
  },
  computed: {
    widgetOptions () {
      return this.$_.map(this.allWidgets, x => {
        return { code: x.id, label: x.widgetName }
      })
    },
    allWidgets () {
      return [
        { id: 1, widgetName: 'Search Types', componentEditor: null, apiEndpoint: 'searchLocation' },
        { id: 2, widgetName: 'Search Location Map', componentEditor: 'search-location-widget-editor', apiEndpoint: 'searchType' },
      ]
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    openAddWidgetDialog () {
      this.show = true;
    },
    closeAddWidgetDialog () {
      this.show = false
      this.selectedWidget = null
      this.$emit('close')
    },
    addWidget () {
      
      const widget = this.$_.find(this.allWidgets, x => x.id === this.selectedWidget.code)
      const route = { 
        name: 'company-dashboard-widget-add', 
        params: { 
          companyId: this.activeUserInfo.companyId,
          dashboardId: this.dashboardId,
          widgetInstanceId: 0,
          componentEditor: widget.componentEditor,
          apiEndpoint: widget.apiEndpoint,
          widgetId: this.selectedWidget.code,
          widgetName: this.selectedWidget.label
        }
      }
      // Clone of route object necessary since this popup will be closed and value will get destroyed
      this.show = false
      this.$emit('widgetSelected', JSON.parse(JSON.stringify(route)))
      
    }
  }
}
</script>

<style>

</style>